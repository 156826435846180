import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getCities, getWarehouses } from '../../utils/api/novaPostaApi';

const NovaPoshtaSelector = ({ updateOrderCity, updateWarehouse }) => {
  const [cities, setCities] = useState([]);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedWarehouse, setSelectedWarehouse] = useState(null);

  useEffect(() => {
    const fetchCities = async () => {
      const citiesData = await getCities();
      setCities(citiesData.map(city => ({ value: city.Ref, label: city.Description })));
    };

    fetchCities();
  }, []);

  const handleCityChange = async (selectedOption) => {
    setSelectedCity(selectedOption);
    updateOrderCity(selectedOption);  // Вызов функции из родительского компонента для обновления города
    const warehousesData = await getWarehouses(selectedOption.value);
    setWarehouses(warehousesData.map(warehouse => ({ value: warehouse.Ref, label: warehouse.Description })));
    setSelectedWarehouse(null);
  };

  const handleWarehouseChange = (selectedOption) => {
    setSelectedWarehouse(selectedOption);
    updateWarehouse(selectedOption);  // Вызов функции из родительского компонента для обновления склада
  };

  return (
    <div>
      <label>Оберіть місто:</label>
      <Select
        options={cities}
        onChange={handleCityChange}
        value={selectedCity}
        placeholder="Оберіть місто"
      />
      {selectedCity && (
        <>
          <label>Оберіть відділення:</label>
          <Select
            options={warehouses}
            onChange={handleWarehouseChange}
            value={selectedWarehouse}
            placeholder="Оберіть відділення"
          />
        </>
      )}
    </div>
  );
};

export default NovaPoshtaSelector;
