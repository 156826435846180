export const variables = {
    BASE_URL: "https://apitest.useek.com.ua/api/",
    USER_API: "https://apitest.useek.com.ua/api/user",
    PRODUCT_API: "https://apitest.useek.com.ua/api/product",
    ORDER_API: "https://apitest.useek.com.ua/api/order",
    PRODUCTSIZE_API: "https://apitest.useek.com.ua/api/productsize",
    ORDERITEM_API: "https://apitest.useek.com.ua/api/orderitem",
    // BASE_URL: "https://localhost:7089/api/",
    // USER_API: "https://localhost:7089/api/user",
    // PRODUCT_API: "https://localhost:7089/api/product",
    // ORDER_API: "https://localhost:7089/api/order",
    // PRODUCTSIZE_API: "https://localhost:7089/api/productsize",
    // ORDERITEM_API: "https://localhost:7089/api/orderitem",
    //"https://localhost:7089/api/product"
}