import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchOrders } from '../../store/actions/orderActions';
import { useStatusString, formatDate } from '../../utils/hooks/useUtil';

import OrderItemsTable from './OrderItemsTable';
import OrderTable from './OrderTable';

function Orders() {
  const dispatch = useDispatch();
  const [orders, setOrders] = useState([]);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const getStatusString = useStatusString();

  useEffect(() => {
    dispatch(fetchOrders()).then((response) => setOrders(response.payload));
  }, [dispatch]);

  const updateOrderStatus = (orderId, newStatus) => {
    setOrders((prevOrders) =>
      prevOrders.map((order) =>
        order.orderID === orderId ? { ...order, status: newStatus } : order
      )
    );

    if (selectedOrder && selectedOrder.orderID === orderId) {
      setSelectedOrder((prevSelectedOrder) => ({
        ...prevSelectedOrder,
        status: newStatus,
      }));
    }
  };

  return (
    <div className="admin-product">
      <table>
        <thead>
          <tr>
            <th>ID</th>
            <th>Дата замовлення</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {orders.map((order, index) => (
            <tr key={index} onClick={() => setSelectedOrder(order)}>
              <td>{order.orderID}</td>
              <td>{formatDate(order.dateTime)}</td>
              <td>{getStatusString(order.status)}</td>
            </tr>
          ))}
        </tbody>
      </table>

      {selectedOrder && (
        <div className="product-panel">
          <OrderTable
            selectedOrder={selectedOrder}
            updateOrderStatus={updateOrderStatus}
          />
          <OrderItemsTable selectedOrder={selectedOrder} />
        </div>
      )}
    </div>
  );
}

export default Orders;
