import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useCart } from '../../utils/hooks/useCart';
import { formatPrice } from '../../utils/hooks/useUtil';
import { Modal } from '../modal/Modal';
import ProductDetail from '../../pages/ProductDetail';

function CartItem() {
  const { removeFromCart, updateQuantity, items } = useCart();
  const [modalIsOpen, setModalIsOpen] = useState(false);

  return (  
    <>
      {items.map((item) => {
        const mainPicture = item.product.pictures.find(picture => picture.isMain);

        return (
          <div className="cart-item" key={`${item.product.id}-${item.size}`}>
            <div className='cart-item-img'>
              {mainPicture ? (
                <img 
                  src={mainPicture.pictureUrl} 
                  alt={item.product.brand} 
                  onClick={() => setModalIsOpen(true)} 
                />
              ) : (
                <p>Зображення відсутнє</p>
              )}
            </div>
            <div className='cart-item-about'>
              <div className='cart-item-left'>
                <p onClick={() => setModalIsOpen(true)}>{item.product.brand} {item.product.name}</p>
                <p>Виробник: {item.product.brand}</p>
                <p>Розмір: {item.size}</p>
                {/* <p>Кількість: {item.quantity}</p> */}
                <a onClick={() => removeFromCart(item.product.id, item.size)}>Видалити</a>
              </div>
              <div className='cart-item-right'>
                <p>{formatPrice(item.price)}</p>
                <div className='cart-item-quantity'>
                  <a onClick={() => updateQuantity(item.product.id, item.size, item.quantity - 1)}>-</a>
                  <input type="number" value={item.quantity} onChange={(e) => {
                    const newQuantity = parseInt(e.target.value);
                    if (!isNaN(newQuantity)) {
                      updateQuantity(item.product.id, item.size, newQuantity);
                    }
                  }} />
                  <a onClick={() => updateQuantity(item.product.id, item.size, item.quantity + 1)}>+</a>
                </div>
              </div>
            </div>
            <Modal
              isOpen={modalIsOpen}
              onClose={() => setModalIsOpen(false)}
            >
              <ProductDetail param={item.product.productID} />
            </Modal>
          </div>
        );
      })}
    </>
  );
}

export default CartItem;
