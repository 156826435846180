import React, { useEffect, useState } from 'react';
import ProductSizes from './SizesTable';
import { useProduct } from '../../utils/hooks/useProduct';
import { useStock } from '../../utils/hooks/useUtil';
import axios from 'axios';
import { variables } from '../../utils/api/variables.js';
import toast from 'react-hot-toast';

function Products() {
  const { products, fetchProducts, updateExistingProduct, removeProduct } = useProduct();
  const [localProduct, setLocalProduct] = useState({});
  const [images, setImages] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [mainImage, setMainImage] = useState('');
  const getStock = useStock();
  const product = products.find((product) => product?.productID === localProduct?.productID);
  const API_URL = variables.PRODUCT_API;

  useEffect(() => {
    fetchProducts();
    if(localProduct){
      setImages(localProduct.pictures);
    }

    const mainImageFromDb = images?.find(image => image.isMain === true);
    if (mainImageFromDb) {
      setMainImage(mainImageFromDb.pictureUrl);
    } else if (localProduct.pictures && localProduct.pictures.length > 0) {
      setMainImage(localProduct.pictures[0].PictureUrl); // Default to the first image
    }
  }, [products]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalProduct(prevState => ({ ...prevState, [name]: value }));
  };

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);
    const previewUrls = files.map(file => URL.createObjectURL(file));

    setImages(files);
    setImagePreviews(previewUrls);

    if (!mainImage && files.length) {
      setMainImage(previewUrls[0]);
    }
  };

  const handleMainImageChange = (fileName) => {
    setMainImage(fileName);
  };

  const handleUploadImages = async () => {
    const formData = new FormData();

    images.forEach((image) => {
      formData.append('pictures', image);
    });

    try {
      const response = await axios.post(`${API_URL}/uploadPhotos`, formData);
      console.log('Files uploaded successfully:', response.data);
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };

  const handleCreateProduct = (localProduct) => {
    const pictures = images.map((file, index) => ({
      PictureUrl: `https://apitest.useek.com.ua/spa/${file.name}`,
      ProductID: localProduct?.productID || '',
      IsMain: mainImage ? mainImage === file.name : index === 0
    }));

    localProduct.Pictures = pictures;
    localProduct.CategoryID = 'lohloh';
    handleUploadImages();
    const response = axios.post(`${API_URL}`, localProduct);
    if (response.status === 200) {
      toast.success(`${localProduct.name} успiшно створено!`, {
        duration: 2000,
        position: 'top-right',
      });
    } else {
      toast.error("Щось пiшло не так:(", {
        duration: 2000,
        position: 'top-right',
      });
    }
  };

  return (
    <div className="admin-product">
      <table className='product-table'>
        <thead>
          <tr>
            <th>ID</th>
            <th>Назва</th>
            <th>Наявність</th>
          </tr>
        </thead>
        <tbody className='tbody-rows'>
          {products.map((product, index) => (
            <tr key={index} onClick={() => setLocalProduct(product)}>
              <td>{product.productID}</td>
              <td>{product.brand} {product.name}</td>
              <td>{getStock(product.inStock)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className='product-panel'>
        <div className='product-info-top'>
        <h2>{localProduct?.brand} {localProduct?.name}</h2>
        <div className='product-panel-img'>
          <img src={mainImage} alt="Main Product" />
        </div>

        <div className="product-panel-info">
          {product && (
            <label className='label-small'>
              ID
              <input value={localProduct?.productID} readOnly />
            </label>
          )}
          <label>
            Бренд
            <input id="productBrand" name="brand" maxLength="50" value={localProduct?.brand || ''} onChange={handleInputChange} />
          </label>
          <label>
            Назва
            <input id="productName" name="name" maxLength="100" value={localProduct?.name || ''} onChange={handleInputChange} />
          </label>
        </div>
        <label>
          Опис
          <textarea id="productDescription" name="description" maxLength="500" rows="4" value={localProduct?.description || ''} onChange={handleInputChange}></textarea>
        </label>
        </div>
        {product && (<ProductSizes product={product} />)}

        <div className='photo-selector'>
          <h2>Додати фото</h2>
          <input type="file" multiple accept="image/*" onChange={handleImageChange} />
          <div className="image-preview">
            {imagePreviews.map((preview, index) => (
              <div key={index} className="image-thumbnail" onClick={() => handleMainImageChange(preview)}>
                <img
                  src={preview}
                  alt={`product-${index}`}
                  className={mainImage === preview ? 'main-image' : ''}
                />
              </div>
            ))}
          </div>
        </div>

        <div className='divider'>
          <button className='second-button' onClick={() => setLocalProduct(null)}>ОЧИСТИТИ</button>
          {product && (<button className='second-button' onClick={() => removeProduct(product?.productID)}>ВИДАЛИТИ</button>)}
          {product?.productID && (<button onClick={() => updateExistingProduct({ productId: product?.productID, product: localProduct })}>ЗБЕРЕГТИ ЗМІНИ</button>)}
          {!product?.productID && (<button onClick={() => handleCreateProduct(localProduct)}>Додати продукт</button>)}
        </div>
      </div>
    </div>
  );
}

export default Products;
