import axios from 'axios';

export const getCities = async () => {
  const response = await axios.post('https://api.novaposhta.ua/v2.0/json/', {
    apiKey: '62173e757cab3a4607206ac1cdad73d4',
    modelName: 'Address',
    calledMethod: 'getCities',
  });
  return response.data.data;
};

export const getWarehouses = async (cityRef) => {
  const response = await axios.post('https://api.novaposhta.ua/v2.0/json/', {
    apiKey: '62173e757cab3a4607206ac1cdad73d4',
    modelName: 'Address',
    calledMethod: 'getWarehouses',
    methodProperties: {
      CityRef: cityRef,
    },
  });
  return response.data.data;
};
