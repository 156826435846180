  import React, { useState, useEffect, useRef } from 'react';

  import Modal from 'react-modal';
  import ReactDOM from 'react-dom';

  import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
  import { icons } from '../assets/icons/icons';

  import { useParams } from 'react-router-dom';
  import { useCart } from "../utils/hooks/useCart";
  import toast, { Toaster } from 'react-hot-toast';
  import { useProduct } from '../utils/hooks/useProduct';
  import { useWishlist } from '../utils/hooks/useWishlist';
  import { formatPrice } from '../utils/hooks/useUtil';
  import { Carousel } from 'react-responsive-carousel';
  import ImageModal from '../components/modal/imageModal';
  import 'react-responsive-carousel/lib/styles/carousel.min.css';
  import './Carousel.scss'
  
  Modal.setAppElement('#root');

  function ProductDetail({ param }) {
    const { addToCart } = useCart();
    const { products, fetchProducts } = useProduct();
    const { wishlistItems, toggleWishlistItem } = useWishlist();
    const [selectedSize, setSelectedSize] = useState(null);
    const [defaultSize, setDefaultSize] = useState(null);
    const product = products.find((product) => product?.productID === String(param));
    const itemExists = wishlistItems.find((item) => item?.productID === product?.productID);

    const [isOpen, setIsOpen] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);

    

    const openModal = (image) => {
      setSelectedImage(image);
      setIsOpen(true);
  };

    const closeModal = () => {
     setIsOpen(false);
      setSelectedImage(null);
  };




    useEffect(() => {
      fetchProducts();
    }, []);

    useEffect(() => {
      if (product) {
        if (!selectedSize) {
          const defaultSizeObj = product?.sizes.find((size) => size.price === product.defaultPrice);
          setSelectedSize(defaultSizeObj);
          setDefaultSize(defaultSizeObj);
        }
      }
    }, [selectedSize, product]);

    const addToCartHandler = () => {
      addToCart({ 
        product: product, 
        size: selectedSize?.size || product.sizes[0].size, price: selectedSize?.price || product.defaultPrice, 
        sizeId: selectedSize?.productSizeID });


      toast.success(`${product.name} доданий у корзину!`, {
        duration: 2000,
        position: 'top-left',
    });

    console.log("Size ID:", selectedSize?.productSizeID);
    };
    

    return (
      <>
        {product && (
          <div className='flex container' >
            <Carousel
               showArrows={true} // Отображать стрелки навигации
               showThumbs={false} // Отключить миниатюры
               infiniteLoop={true} // Включить бесконечный цикл
               transitionTime={350} // Время анимации перехода
               dynamicHeight={true}
               showStatus={false}
            >
              {product.pictures.map((picture, index) => (
                <div className='product-detail-img flex-2' key={index} onClick={() => openModal(picture.pictureUrl)}> 
                   <img src={picture.pictureUrl} alt={`Product Image ${index + 1}`} /> 
                </div>
              ))}
              {/* /* <img src={product.imageURL} alt="" />
            </div>
            <div className='product-detail-img flex-2'>
              <img src={product.imageURL} alt="" />
            </div>
            <div className='product-detail-img flex-2'>
              <img src={product.imageURL} alt="" />
            </div>
            <div className='product-detail-img flex-2'>
              <img src={product.imageURL} alt="" /> */ }
            </Carousel>
            <div className='product-detail-about flex-1'>
              <h2>{product.brand}</h2>
              <h1>{product.brand} {product.name}</h1>
              <p>
              {selectedSize ? `${formatPrice(selectedSize?.price)}` : `${formatPrice(product.defaultPrice)}`}
            </p>     
          <div className='size-selector'>
              <label htmlFor="size-select">Доступні розміри:</label>
              <select param="size-select" 
                value={selectedSize ? JSON.stringify(selectedSize) : JSON.stringify(defaultSize)} 
                onChange={(e) => {
                  const selected = JSON.parse(e.target.value);
                  console.log('Selected size:', selected);
                  setSelectedSize(selected);
                  }}>
                {product?.sizes?.slice()?.sort((a,b) => a.size - b.size)?.map((size, index) => (
                  <option key={index} value={JSON.stringify(size)}>
                  {size?.size} - {formatPrice(size?.price)} {size.quantity <= 3 ? `(тільки ${size.quantity} залишилося)`  : "" }
                  </option>
                ))}
              </select>
            </div>
            <button onClick={() => addToCartHandler()}>В КОРЗИНУ</button>
            {/* <div className='divider'> */}
              {/* <button onClick={() => addToCartHandler()}>В КОРЗИНУ</button> */}
              {/* <button className='second-button' onClick={() => toggleWishlistItem(product)}>
                <span>WISHLIST</span><FontAwesomeIcon icon={itemExists ? icons.heartFull : icons.heart}/>
              </button> */}
            {/* </div> */}
            <p>{product.description}</p>
          </div>
          <Toaster />
          <ImageModal 
                isOpen={isOpen} 
                onClose={closeModal} 
                imageSrc={selectedImage} 
            />
          
        </div>
      )}
    </>
  );
}

export default ProductDetail;
