import React, { useState, useEffect } from 'react';
import orderApi from '../../utils/api/orderApi';

function OrderTable({ selectedOrder, updateOrderStatus }) {
  const [status, setStatus] = useState(selectedOrder?.status || 0);
  const [prevStatus, setPrevStatus] = useState(selectedOrder?.status || 0);

  useEffect(() => {
    setStatus(selectedOrder?.status || 0);
    setPrevStatus(selectedOrder?.status || 0);
  }, [selectedOrder]);

  const handleStatusChange = async (event) => {
    const newStatus = parseInt(event.target.value);
    setPrevStatus(status);
    setStatus(newStatus);

    const updatedOrder = {
      ...selectedOrder,
      status: newStatus,
    };

    try {
      const response = await orderApi.updateOrder(updatedOrder.orderID, updatedOrder);

      if (response.status == 200) {
        updateOrderStatus(updatedOrder.orderID, newStatus);
      }
    } catch (error) {
      console.error('Ошибка при обновлении заказа:', error);
      setStatus(prevStatus);
    }
  };

  return (
    <>
      <div className="divider">
        <label>
          Імʼя
          <input value={selectedOrder?.userName || ''} readOnly />
        </label>
        <label>
          Прізвище
          <input value={selectedOrder?.userSurname || ''} readOnly />
        </label>
      </div>
      <label>
        Email
        <input value={selectedOrder?.userEmail || ''} readOnly />
      </label>
      <label>
        Телефон
        <input value={selectedOrder?.userPhone || ''} readOnly />
      </label>
      <div className="divider">
        <label>
          Спосіб отримання
          <input value={selectedOrder?.deliveryType || ''} readOnly />
        </label>
      </div>
      <label>
        Адреса
        <input value={selectedOrder?.address || ''} readOnly />
      </label>
      {selectedOrder?.npCity != null && (
        <label>
          Нова Пошта місто
          <input value={selectedOrder?.npCity || ''} readOnly />
        </label>
      )}
      <div className="divider">
        <label>
          Оновити статус
        <select name="status" value={status} onChange={handleStatusChange}>
          <option value={0} disabled={status === 0}>Pending</option>
          <option value={1} disabled={status === 1}>Processing</option>
          <option value={2} disabled={status === 2}>Shipped</option>
          <option value={3} disabled={status === 3}>Delivered</option>
          <option value={4} disabled={status === 4}>Cancelled</option>
        </select>
        </label>
        <label>
          Total Price
          <input value={selectedOrder?.totalPrice || ''} readOnly />
        </label>
      </div>
    </>
  );
}

export default OrderTable;
