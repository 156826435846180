import React from 'react';
import hero from "../assets/icons/hero1.png"
import Swiper from '../components/home/Swiper';

function Home() {
  return (
    <>
      <div className='hero'>
        <div className='hero-circle'> </div>
        {/* <img className='hero-img' src={hero} alt="" /> */}
      </div>
      {/* <div className='hero-about'>
        <div>
          <p>LIMITED OFFER</p>
          <h3> SAVE 10%</h3>
          <p>USE DISCOUNT</p>
          <button>10OFF</button>
        </div>
      </div>   */}
      <div className='container'>

          <Swiper />

      </div>
    </>
  );
}

export default Home;